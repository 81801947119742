import React from 'react';
import './main.scss';

import Pagination from '../Pagination/Pagination';
import Pagenav from '../Pagenav/Pagenav';
import Footer from '../Footer/Footer';

const Main = ({ children }) => {
	return (
		<main className="main">
			<div className="main__content">
				{children ? children[1] : 'Error fetching content'}
				{/* <Pagination fields={children ? children[2] : {}} /> */}
			</div>
			<div className="main__pagenav">
				<Pagenav
					tableOfContents={children ? children[0] : { items: [] }}
					fields={children ? children[2] : {}}
				/>
			</div>
			<div className="main__footer">
				<Footer />
			</div>
		</main>
	);
};

export default Main;
