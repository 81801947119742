import React, { useState } from 'react';

import FeedbackForm from '../FeedbackForm/FeedbackForm';
import SatisfactoryCard from '../SatisfactoryCard/SatisfactoryCard';
import ExternalLink from '../../assets/images/svg/external-link.svg';
import './footer.scss';

const Footer = () => {
	const [helpful, setHelpful] = useState(false)
	const [notHelpful, setNotHelpful] = useState(false)
	const [showThanks, setShowThanks] = useState(false)

	const updateShowThanks = () => {
		setShowThanks(!showThanks);
	}

	const updateNotHelpful = () => {
		setNotHelpful(!notHelpful);
	}

	return (
		<footer className="footer">
			<div className="footer__action">
				<div className={` helpful--success ${helpful ? 'show' : ''}`}>
					<span>Awesome! We’re happy it was ✨✨</span>
				</div>
				<div className="footer__feedback">
					<FeedbackForm notHelpful={notHelpful} setNotHelpful={updateNotHelpful} updateShowThanks={updateShowThanks} />
					<SatisfactoryCard showThanks={showThanks} />
				</div>
				<div className={`footer__poll ${helpful ? 'hide' : ''}`}>
					<p className="footer__text">This page was</p>
					<div className="footer__button footer__action--helpful" onClick={() => setHelpful(true)}>
						<span role="img" aria-label="thumbs-up">&#128077;&#127998; </span>
						<span>Helpful</span>
					</div>
					<div className="footer__button footer__action--not-helpful" onClick={() => setNotHelpful(true)}>
						<span role="img" aria-label="thumbs-down">&#128078;&#127998; </span>
						<span>Not Helpful</span>
					</div>
				</div>
			</div>
			<div className="footer__info">
				<a className="footer__link" href="https://slack.paystack.com/" target="_blank" rel="noopener noreferrer" >
					<span className="footer__link__header">
						Join Payslack
						<ExternalLink className="footer__link__icon icon--right" />
					</span>
					<span className="footer__link__details">
						Ask questions and discuss ideas with 2000+ developers on
						Slack
					</span>
				</a>
				<a className="footer__link" href="https://www.npmjs.com/package/@paystack-oss/dev-cli" target="_blank" rel="noopener noreferrer">
					{/* <span className="footer__link__header">
						Paystack for Developers
						<ExternalLink className="footer__link__icon icon--right" />
					</span>
					<span className="footer__link__details">
						Check out our marketing page for a quick primer on the Paystack API
					</span> */}
					<span className="footer__link__header">
						Paystack CLI
						<ExternalLink className="footer__link__icon icon--right" />
					</span>
					<span className="footer__link__details">
						Learn how to use our CLI to improve your integration experience
					</span>
				</a>
				<a className="footer__link" href="https://support.paystack.com" target="_blank" rel="noopener noreferrer">
					<span className="footer__link__header">
						Need something else?
						<ExternalLink className="footer__link__icon icon--right" />
					</span>
					<span className="footer__link__details">
						If you have any questions or need general help, visit our support
						page
					</span>
				</a>
			</div>
		</footer>
	);
};

export default Footer;
