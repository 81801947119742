import React from 'react';
import { Link } from 'gatsby';

import './pagenav.scss';

const Pagenav = ({ tableOfContents, fields }) => {
	return (
		<nav className="pagenav">
			{Object.keys(tableOfContents).length === 0 &&
			tableOfContents.constructor === Object ? (
				''
			) : (
				<>
					<h6 className="pagenav__label">On this Page</h6>
					<ul className="pagenav__links">
						{tableOfContents.items.map((item, index) => (
							<li className="pagenav-item" key={index}>
								<Link
									className="pagenav-item__link"
									to={`${fields.slug}${item.url}`}
								>
									{item.title}
								</Link>
							</li>
						))}
					</ul>
				</>
			)}
		</nav>
	);
};

export default Pagenav;
