import React from 'react';
import { Link } from 'gatsby';

import './header.scss';

import Search from '../Search/Search';
import Logo from '../../assets/images/svg/paystack.svg';

const Header = props => {
	return (
		<header className="header">
			<div className="header__brand">
				<Link to="/">
					<Logo />
					<span>docs</span>
				</Link>
			</div>
			<div className="header__search">
				<Search
					isSearchMobile={ props.isSearchMobile }
					toggleSearch={ props.toggleSearch } />
			</div>
			<div className="header__actions">
				<div className="actions">
					<div>
						<a className="link-text" href={ process.env.GATSBY_API_REFERENCE_URL } target="_blank" rel="noopener noreferrer">API</a>
					</div>
					<div>
						<a className="button" href="https://dashboard.paystack.com/" target="_blank" rel="noopener noreferrer">
							Dashboard
						</a>
					</div>
					
				</div>
			</div>
		</header>
	);
}

export default Header;
