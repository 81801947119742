const navLinks = [
	{
		title: 'Home',
		route: '/',
		children: [],
		pagination: [
			// {
			// 	title: 'API Reference',
			// 	route: 'https://developers.paystack.co/v2.0/reference',
			// 	type: 'external'
			// },
			// {
			// 	title: 'Payments',
			// 	route: '/payments',
			// },
			// {
			// 	title: 'Libraries and Plugins',
			// 	route: '/libraries-and-plugins',
			// },
			// {
			// 	title: 'Guides',
			// 	route: '/guides',
			// },
		],
	},
	{
		title: 'Payments',
		route: '/payments',
		children: [
			{
				title: 'Overview',
				route: '/payments',
				children: [],
				pagination: [],
			},
			{
				title: 'Accept Payments',
				route: '/payments/accept-payments',
				children: [
					{
						title: 'On the web',
						route: '/payments/paystack-pop',
						children: [],
						pagination: [],
					},
					{
						title: 'On mobile',
						route: '/payments/paystack-pop',
						children: [],
						pagination: [],
					},
					{
						title: 'Via plugins',
						route: '/payments/paystack-pop',
						children: [],
						pagination: [],
					},
				],
				pagination: [],
			},
			{
				title: 'Webhooks',
				route: '/payments/webhooks',
				children: [],
				pagination: [],
			},
			{
				title: 'Verify Payments',
				route: '/payments/verify-payments',
				children: [],
				pagination: [],
			},
			{
				title: 'Payment Channels',
				route: '/payments/payment-channels',
				children: [],
				pagination: [],
			},
			{
				title: 'Recurring Charges',
				route: '/payments/recurring-charges',
				children: [],
				pagination: [],
			},
			{
				title: 'Subscriptions',
				route: '/payments/subscriptions',
				children: [],
				pagination: [],
			},
			{
				title: 'Split Payments',
				route: '/payments/split-payments',
				children: [],
				pagination: [],
			},
			{
				title: 'Multi-split Payments',
				route: '/payments/multi-split-payments',
				children: [],
				pagination: [],
			},
			{
				title:"Dedicated NUBAN",
				route:"/payments/dedicated-nuban",
				children:[],
				pagination:[]

			},
			{
				title:"Apple Pay",
				route:"/payments/apple-pay",
				children:[],
				pagination:[]

			},
			{
				title: 'Partial Debits',
				route: '/payments/partial-debits',
				children: [],
				pagination: [],
			},
			{
				title: 'Manage Disputes',
				route: '/payments/manage-disputes',
				children: [],
				pagination: [],
			},
			{
				title: 'Refunds',
				route: '/payments/refunds',
				children: [],
				pagination: [],
			},
			{
				title: 'Test Payments',
				route: '/payments/test-payments',
				children: [],
				pagination: [],
			},
			{
				title: 'Metadata',
				route: '/payments/metadata',
				children: [],
				pagination: [],
			},
			// {
			// 	title: 'Custom Filters',
			// 	route: '/payments/custom-filters',
			// 	children: [],
			// 	pagination: [],
			// },
			// {
			// 	title: 'Custom Fields',
			// 	route: '/payments/custom-fields',
			// 	children: [],
			// 	pagination: [],
			// },
		],
		pagination: [
			// {
			// 	title: 'Accept Payments',
			// 	route: '/payments/accept-payments',
			// },
			// {
			// 	title: 'Recurring Payments',
			// 	route: '/payments/recurring-charges',
			// },
			// {
			// 	title: 'Libraries and Plugins',
			// 	route: '/libraries-and-plugins',
			// },
		],
	},
	{
		title: 'Transfers',
		route: '/transfers',
		children: [
			{
				title: 'Overview',
				route: '/transfers',
				children: [],
				pagination: [],
			},
			{
				title: 'Single Transfers',
				route: '/transfers/single-transfers',
				children: [],
				pagination: [],
			},
			{
				title: 'Bulk Transfers',
				route: '/transfers/bulk-transfers',
				children: [],
				pagination: [],
			},
		],
		pagination: [
			// {
			// 	title: 'Single Transfers',
			// 	route: '/transfers/single-transfers',
			// },
			// {
			// 	title: 'Bulk Transfers',
			// 	route: '/transfers/bulk-transfers',
			// },
			// {
			// 	title: 'Identity Verification',
			// 	route: '/identity-verification',
			// },
		],
	},
	{
		title: 'Identity Verification',
		route: '/identity-verification',
		children: [
			{
				title: 'Overview',
				route: '/identity-verification',
				children: [],
				pagination: [],
			},
			{
				title: 'Verify BVN Match',
				route: '/identity-verification/verify-bvn-match',
				children: [],
				pagination: [],
			},
			{
				title: 'Resolve Account Number',
				route: '/identity-verification/resolve-account-number',
				children: [],
				pagination: [],
			},
			{
				title: 'Resolve Card BIN',
				route: '/identity-verification/resolve-card-bin',
				children: [],
				pagination: [],
			},
			{
				title:"Validate Customer",
				route:'/identity-verification/validate-customer',
				children:[],
				pagination:[]
			},
		],
		pagination: [
			// {
			// 	title: 'Accept Payments',
			// 	route: '/payments/accept-payments',
			// },
			// {
			// 	title: 'Payments',
			// 	route: '/payments',
			// },
			// {
			// 	title: 'Transfers',
			// 	route: '/transfers',
			// },
		],
	},
	{
		title: 'Libraries & Plugins',
		route: '/libraries-and-plugins',
		children: [
			{
				title: 'Overview',
				route: '/libraries-and-plugins',
				children: [],
				pagination: [],
			},
			{
				title: 'Libraries',
				route: '/libraries-and-plugins/libraries',
				children: [],
				pagination: [],
			},
			{
				title: 'Plugins',
				route: '/libraries-and-plugins/plugins',
				children: [],
				pagination: [],
			},
		],
		pagination: [
			// {
			// 	title: 'Accept Payments',
			// 	route: '/payments/accept-payments',
			// },
			// {
			// 	title: 'Payments',
			// 	route: '/payments',
			// },
			// {
			// 	title: 'Transfers',
			// 	route: '/transfers',
			// },
		],
	},
	{
		title: 'Guides',
		route: '/guides',
		children: [
			{
				title: 'Overview',
				route: '/guides',
				children: [],
				pagination: [],
			},
			{
				title: 'Accept Payments on your Vue App',
				route: '/guides/accept_payments_on_your_vue_app',
				children: [],
				pagination: [],
			},
			{
				title: 'Accept Payments on your React App',
				route: '/guides/accept_payments_on_your_react_app',
				children: [],
				pagination: [],
			},
			{
				title: 'Accept Payments on your Android App',
				route: '/guides/accept_payments_on_your_android_app',
				children: [],
				pagination: [],
			},
			{
				title: 'Using the Paystack Checkout in a Mobile WebView',
				route: '/guides/using_the_paystack_checkout_in_a_mobile_webview',
				children: [],
				pagination: [],
			},
			{
				title: 'Migrating from InlineJS V1 to V2',
				route: '/guides/migrating-from-inlinejs-v1-to-v2',
				children: [],
				pagination: [],
			}
		],
		pagination: [],
	},
];

module.exports = navLinks;
