import React from 'react';
import { Link } from 'gatsby';

const Subnav = ({ sublinks, inner }) => {
	return (
		<ul className={`sublink ${inner ? 'sublink__inner' : ''}`}>
			{sublinks.map((sublink, index) => (
				<li className={`sublink__item ${inner ? 'sublink__inner__item' : ''} `} key={index}>
					{sublink.title === 'Overview' ? 
						(
							<Link
								to={sublink.route}
								className="sublink__item__text"
								activeClassName="sublink__item__text--active">
								{sublink.title}
							</Link>
						) 
						: 
						(
							<Link
								to={sublink.route}
								className="sublink__item__text"
								getProps={({ isPartiallyCurrent }) =>
										isPartiallyCurrent ? { className: "sublink__item__text sublink__item__text--active" } : null
									}>
								{sublink.title}
							</Link>
						) 
					}
					{/* <Link
						to={sublink.route}
						className="sublink__item__text"
						getProps={({ isPartiallyCurrent }) =>
                isPartiallyCurrent ? { className: "sublink__item__text sublink__item__text--active" } : null
              }>
						{sublink.title}
					</Link> */}
					{/* {sublink.children.length > 0 ? (
						<Subnav sublinks={sublink.children} inner={true} />
					) : (
						''
					)} */}
				</li>
			))}
		</ul>
	);
};

export default Subnav;
