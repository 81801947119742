import React, { useState } from 'react';
import styled from 'styled-components'

import Sucessful from '../../assets/images/svg/successful.svg';

const Card = styled.div`
  display: none;
  
  &.active {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background: #FFFFFF;
    border: 1px solid $gray;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 24px;
    width: 305px;
    height: 266px;
    position: absolute;
    bottom: 0px;
    left: 200px;
  }
  span {
    margin-top: 14px;
    font-weight: 500;
    color: #285056;
  }
`

const Footer = ({showThanks}) => {

	return (
		<Card className={` ${showThanks ? 'active' : ''}`}>
      <Sucessful />
      <span>Thanks for your feedback</span>
    </Card>
	);
};

export default Footer;
