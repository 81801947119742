import React, { Component } from 'react';
import { Link } from 'gatsby';
import navLink from '../../../nav-config';

import Subnav from './Subnav';

import Home from '../../assets/images/svg/home.svg';
import Payments from '../../assets/images/svg/payments.svg';
import Transfers from '../../assets/images/svg/transfers.svg';
import Identity from '../../assets/images/svg/identity-verification.svg';
import Libraries from '../../assets/images/svg/libraries-plugins.svg';
import Guides from '../../assets/images/svg/guides.svg';
import Cancel from '../../assets/images/svg/cancel.svg';
import Callout from '../../assets/images/svg/callout.svg';
import Moon from '../../assets/images/svg/moon.svg';

import './nav.scss';

class Nav extends Component {
	getIcon = title => {
		const icons = {
			home: <Home />,
			payments: <Payments />,
			transfers: <Transfers />,
			identity: <Identity />,
			libraries: <Libraries />,
			guides: <Guides />,
		};

		return title ? icons[title.toLowerCase().split(' ')[0]] : '';
	};

	toggleMenu = ({ isPartiallyCurrent }) => {
		return isPartiallyCurrent
			? { className: 'navitem__text navitem__text--active' }
			: null;
	};

	render() {
		return (
			<nav className="nav">
				<div className="nav__action">
					<button className="action__button" onClick={this.props.toggleNav}>
						<Cancel className="action__button__icon" />
						<span className="action__button__text action__button__text--right">
							Close
						</span>
					</button>
				</div>
				<div className="nav__content">
					<ul className="nav__links">
						{navLink.map((link, index) => (
							<li className="navitem" key={index}>
								{link.route === '/' ? (
									<Link
										to={link.route}
										className="navitem__text"
										activeClassName="navitem__text--active"
									>
										<span className="navitem__icon">
											{this.getIcon(link.title)}
										</span>
										<span>{link.title}</span>
									</Link>
								) : (
									<Link
										to={link.route}
										className="navitem__text"
										getProps={this.toggleMenu}
										{...this.props}
									>
										<span className="navitem__icon">
											{this.getIcon(link.title)}
										</span>
										<span>{link.title}</span>
									</Link>
								)}
								{link.children.length > 0 ? (
									<Subnav sublinks={link.children} />
								) : (
									''
								)}
							</li>
						))}
					</ul>
				</div>
				<div className="nav__footer">
					<div className="settings">
						<div>
							<Link to="/changelog/api"
								partiallyActive={true} 
								className="settings__link"
								activeClassName="settings__link--active">
									API Changelog
							</Link>
						</div>
						{/* <div className="settings__lang">
							<Callout className="settings__icon" />
							<span> English</span>
						</div> */}
						{/* <div className="settings__theme">
							<Moon className="settings__icon" />
							<span> Light mode</span>
						</div> */}
					</div>
				</div>
			</nav>
		);
	}
}

export default Nav;
