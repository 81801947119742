import React, { useState, useEffect, useRef } from 'react';
import algoliasearch from 'algoliasearch';

import SearchResult from '../SearchResult/SearchResult';
import Magnifier from '../../assets/images/svg/search.svg';

import './search.scss';

const Search = props => {
	const [focused, setFocused] = useState(false)
	const [placeholder, setPlaceholder] = useState('Search Documentation')
	const [query, setQuery] = useState('')
	const [hits, setHits] = useState([])
	const client = algoliasearch(process.env.GATSBY_SEARCH_APP_ID, process.env.GATSBY_SEARCH_API_KEY);
	const index = client.initIndex('paystack');
	const searchInput = useRef()

	useEffect(() => {
		if(props.isSearchMobile) {
			searchInput.current && searchInput.current.focus();
		}
	}, [props.isSearchMobile])
	
	useEffect(() => {
		index.search(query, {
			attributesToRetrieve: ['content', 'hierarchy', 'url'],
  		hitsPerPage: 10,
		}).then(({ hits }) => {
			setHits(hits)
		});
	}, [query])


	const onFocus = () => {
		setFocused(!focused)
		setPlaceholder('')
	};

	const clearSearch = () => {
		if(props.isSearchMobile) {
			props.toggleSearch()
		}
		setQuery('')
		setHits([])
		setFocused(false)
		setPlaceholder('Search Documentation')
	}

	const onBlur = () => {
		props.toggleSearch.bind(this);
		setTimeout(function() {
			clearSearch()
		}, 150);
	};

	const onChange = (event) => {
		setQuery(event.target.value);
	};

	return (
		<div className={`search ${props.isSearchMobile ? 'search--mobile' : ''}`}>
			<div className={`search__field ${ props.isSearchMobile ? 'search__field--mobile' : ''}`}>
				<input
					ref={searchInput}
					className={`search__field__input ${ focused  ? 'search__field__icon--left--active' : ''} `}
					type="text"
					name="query"
					value={query}
					placeholder={placeholder}
					title={placeholder}
					onFocus={onFocus}
					onBlur={onBlur}
					onChange={onChange}
					autoComplete="off" />
				<Magnifier className={`search__field__icon search__field__icon--left 
					${focused ? 'search__field__icon--left--active' : ''}`} />
				<span className={`search__field__icon--right ${focused ? 'search__field__icon--right--active' : ''}`}
					onClick={clearSearch}>
					Cancel
				</span>
			</div>
			<div className={`search__result ${ focused ? 'search__result--active' : ''} 
				${props.isSearchMobile ? 'search__result--active search__result--mobile' : '' }`}>
				<div className={`result__loading ${query.length > 0 ? 'result__loading--active' : ''} 
					${ props.isSearchMobile ? 'result__loading--mobile' : '' }`}>
					{hits && hits.length > 0 ?
						(<SearchResult hits={hits} clearSearch={clearSearch} hasIcon="true"/>) : 
					(<div className="no-result">
							<p>No result found for: <strong>{query}</strong></p>
						</div>) }
					{/* {props.isSearchMobile ? (<div className="search__prompt">Search Paystack docs</div>) : ''} */}
				</div>
				<div className={`search__footer ${query.length > 0 ? 'search__footer--active' : ''}`}>
					<a href="https://www.algolia.com/ref/docsearch/" target="_blank"></a>
				</div>
			</div>
		</div>
	);
}

export default Search;
