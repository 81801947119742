import React, { Component } from 'react';
import axios from 'axios'

import './feedback-form.scss';
import Cancel from '../../assets/images/svg/cancel.svg';

class FeedbackForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			message: "",
			email: "",
			loading: false,
			emailIsValid: false
		};

		this.updateEmail = this.updateEmail.bind(this);
		this.updateMessage = this.updateMessage.bind(this);
	}

	concludeFeedback = () => {
		this.setState({
			message: "",
			email: "",
			loading: false
		})
		this.props.setNotHelpful()
		this.props.updateShowThanks()

		const myProp = this.props

		setTimeout(function () {
			myProp.updateShowThanks()
		}, 3000);
	}

	cancelFeedback = () => {
		this.setState({
			message: "",
			email: "",
			loading: false,
		})
		this.props.setNotHelpful()
	}

	validateEmail(email) {
		if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
			this.setState({ emailIsValid: true })
			return
		}
		this.setState({ emailIsValid: false })
	}

	submitFeedback = () => {
		const url = typeof window !== 'undefined' ? window.location.href : ''
		const path = url ? new URL(url).pathname : ''

		this.setState({ loading: true })

		const data = {
			"request": {
				"requester": {
					"name": this.state.email.length > 0 ? this.state.email : "Anonymous customer"
				},
				"subject": `Documentation Feedback: ${path}`,
				"comment": {
					"body": this.state.message
				},
				"custom_fields": [
					{
						// Custom field for Enquiry Support field
						"id": 360019692440,
						"value": "other_for_merchant",
					},
				],
				"via": {
					"channel": "documentation"
				}
			}
		}

		axios.post(process.env.GATSBY_ZENDESK_URL, data, {
			headers: { 'Content-type': 'application/json' },
		}).then(res => {
			this.concludeFeedback()
		})
	};

	updateMessage(event) {
		this.setState({ message: event.target.value });
	}

	updateEmail(event) {
		this.setState({ email: event.target.value }, () => this.validateEmail(this.state.email));
	}


	render() {
		return (
			<div className={`feedback-form ${this.props.notHelpful ? 'show' : ''}`}>
				<div className="feedback-form__header">
					<h4>Help us fix that</h4>
					<span onClick={this.cancelFeedback}><Cancel /></span>
				</div>

				<form>
					<div className="form-element">
						<label>How can we make this page better?</label>
						<textarea value={this.state.message} onChange={this.updateMessage}></textarea>
					</div>
					<div className="form-element">
						<label>Your email (so we can reach you)</label>
						<input
							type="email"
							value={this.state.email}
							onChange={this.updateEmail}
						/>
					</div>
					<div className="form-button">
						<button type="button" className="button" onClick={this.submitFeedback} disabled={this.state.loading || this.state.message.length < 15 || !this.state.emailIsValid}>
							{this.state.loading ? <span className="button--spinner"></span> : 'Send my feedback'}
						</button>
					</div>
				</form>
			</div>
		);
	}
}

export default FeedbackForm;
