import React from 'react';
import { Link } from 'gatsby';
import navLink from '../../../nav-config';

import NavArrow from '../../assets/images/svg/nav-arrow.svg';

import './pagination.scss';

const Pagination = ({ fields }) => {
	const slug =
		fields.slug.length > 1
			? fields.slug.substring(0, fields.slug.length - 1)
			: '/';

	function getRoutePagination(links) {
		for (let link of links) {
			if (link.route === slug) {
				return link.pagination;
			}
			if (link.children && link.children.length > 0) {
				let pagination = getRoutePagination(link.children);
				if (pagination) return pagination;
			}
		}
	}

	let pagination = getRoutePagination(navLink);

	return (
		<div className="pagination">
			{pagination.length > 0 ? (
				<div>
					<h6>Read More</h6>
					<ul className="pagination__container">
						{pagination.map((link, index) => (
							<li className="pagination__item" key={index}>
								<NavArrow />
								{link.type && link.type === 'external' ? (
									<a
										className="pagination__link"
										href={link.route}
										target="_blank"
										rel="noopener noreferrer">
										{link.title}
									</a>
								)
								 : (
								 <Link to={link.route} className="pagination__link">
									{link.title}
								</Link> 
							 ) }
							</li>
						))}
					</ul>
				</div>
			) : (
				''
			)}
		</div>
	);
};
export default Pagination;
