import React, { useState, useEffect } from 'react';

import Header from '../Header/Header';
import Nav from '../Nav/Nav';
import Main from '../Main/Main';

import Hamburger from '../../assets/images/svg/hamburger.svg';
import Magnifier from '../../assets/images/svg/search.svg';

import './layout.scss';

const Layout = (props) => {
	const [overlay, setOverlay] = useState(false)
	const [mobileSearch, setMobileSearch] = useState(false)
	const [mobileMenu, setMobileMenu] = useState(false)
	const [mobileActions, setMobileActions] = useState(false)
	

	useEffect(() => {
		const body = window.document.body
		if(overlay || mobileSearch) {
			body.classList.add("disable-scroll")
		} else {
			body.classList.remove("disable-scroll")
		}
  }, [overlay, mobileSearch]);

	const toggleSearch = () => {
		setMobileSearch(!mobileSearch)
		// setOverlay(!overlay)
		setMobileActions(!mobileActions)
	};

	const toggleNav = () => {
		setMobileMenu(!mobileMenu)
		setOverlay(!overlay)
		setMobileActions(!mobileActions)
	};

	return (
		<div className="layout">
			<div className={`layout__overlay ${overlay ? 'layout__overlay--mobile' : ''}`}></div>
			<div className="layout__header">
				<Header isSearchMobile={ mobileSearch } toggleSearch={ toggleSearch } />
				<div className={`mobile__actions ${mobileActions ? 'mobile__actions--toggle' : ''}`}>
					<button	className="action__button button--search" onClick={toggleSearch}>
						<Magnifier className="action__button__icon" />
						<span className="button__text button__text--right">
							Search
						</span>
					</button>
					<button className="action__button action__button--right-space" onClick={toggleNav}>
						<Hamburger className="action__button__icon" />
						<span className="button__text button__text--right">
							Menu
						</span>
					</button>
				</div>
			</div>
			<div className={`layout__nav ${ mobileMenu ? 'layout__nav--mobile' : ''}`}>
				<Nav toggleNav={ toggleNav } />
			</div>
			<div className="layout__body">
				<Main children={props.children} />
			</div>
		</div>
	);
}

export default Layout;
